import { Alert, Box, MenuItem, Select } from "@mui/material";

const cfgObjects = ["CFG_REOA_MAIN", "CFG_WWR_DUMMY", "CFG_POSITIONING"];

export function CFGObjectSelect(): JSX.Element {
  return (
    <Alert severity="error">
      <Box marginBottom={"0.75rem"}>
        No cfgObjectId set. Please set an cfgObject or select the cfgObject you
        were looking for. When including this application in an iFrame, check
        the url of the iFrame for the correct cfgObjectId.
      </Box>
      <Select
        value=""
        sx={{ minWidth: "200px", marginBlock: "0.5rem" }}
        onChange={(event) => {
          window.location.href =
            window.location.href = `/#/${event.target.value}`;
          window.location.reload();
        }}
      >
        {cfgObjects.map((cfgObject) => {
          return (
            <MenuItem value={cfgObject} key={cfgObject}>
              {cfgObject}
            </MenuItem>
          );
        })}
      </Select>
    </Alert>
  );
}
