import Box from "@mui/material/Box";

import { Paragraph } from "./types";

type Props = {
  paragraphs: Paragraph[];
  componentKey: string;
};

export function OptionalComponentParagraphs({
  paragraphs,
  componentKey,
}: Props): JSX.Element {
  return (
    <>
      {paragraphs?.map((paragraph, index) => {
        switch (typeof paragraph) {
          case "string":
            return (
              <Box marginBlockStart="1rem" key={`${componentKey}_${index}`}>
                {paragraph}
              </Box>
            );

          case "object":
            return (
              <Box
                display="flex"
                marginBlockStart="1rem"
                justifyContent="space-evenly"
                key={`${componentKey}_${index}`}
              >
                {paragraph.map((element) => {
                  return (
                    <Box
                      display="flex"
                      flexDirection="column"
                      flexBasis="5rem"
                      key={`${element.subheader}_${componentKey}`}
                    >
                      <Box
                        display="flex"
                        height="100px"
                        marginBlock="1em"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box
                          component="img"
                          maxWidth="100px"
                          maxHeight="100px"
                          src={element.url}
                          alt={`img_${componentKey}`}
                        />
                      </Box>
                      <Box textAlign="center">{element.subheader}</Box>
                    </Box>
                  );
                })}
              </Box>
            );

          default:
            return <></>;
        }
      })}
    </>
  );
}
