import { CustomDialog } from "../CustomDialog/CustomDialog";
import { Image } from "../Image/Image";

export function ImagePreview({
  imageId,
  alt,
}: {
  imageId?: string | null | undefined;
  alt?: string | null | undefined;
}): JSX.Element {
  if (!imageId) {
    return <Image width={66} height={44} />;
  }
  return (
    <CustomDialog
      buttonProps={{
        style: { padding: 0 },
        children: <Image alt={alt} imageId={imageId} width={66} height={44} />,
      }}
      dialog={{
        dialogProps: {
          maxWidth: "lg",
        },
        content: <Image alt={alt} imageId={imageId} width={800} height={600} />,
      }}
    />
  );
}
