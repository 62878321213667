export type ShortLanguageCode =
  | "de"
  | "en"
  | "en-us"
  | "fr"
  | "it"
  | "es"
  | "sk"
  | "zh"
  | "pl"
  | "ko"
  | "nl";
type LongLanguageCode =
  | "de-DE"
  | "en-US"
  | "fr-FR"
  | "it-IT"
  | "es-ES"
  | "sk-SK"
  | "zh-CN"
  | "pl-PL"
  | "ko-KR"
  | "nl-NL";

type LanguageCode = {
  [code in ShortLanguageCode | string]?: LongLanguageCode;
};

export function convertToLongLanguageCode(
  shortCode: ShortLanguageCode | string,
): LongLanguageCode | string {
  const languageMap: LanguageCode = {
    de: "de-DE",
    en: "en-US",
    "en-us": "en-US",
    fr: "fr-FR",
    it: "it-IT",
    es: "es-ES",
    sk: "sk-SK",
    zh: "zh-CN",
    pl: "pl-PL",
    ko: "ko-KR",
    nl: "nl-NL",
  };

  return languageMap[shortCode.toLowerCase()] || shortCode;
}
