import { Box, Grid } from "@mui/material";
import { CommonErrorBoundary } from "@plansoft-configuration-renderer/components";
import { OAuthManager } from "@plansoft-configuration-renderer/components";
import {
  GatewayDest,
  GatewayProvider,
} from "@plansoft-configuration-renderer/gateway";

import { convertToLongLanguageCode } from "../tools/convertToLongLanguageCode";

import { CFGObjectSelect } from "./CFGObjectSelect";
import { MinimalAppContext } from "./MinimalAppContext";
import { MinimalRenderer } from "./MinimalRenderer";

const cfgObjectId = window.location.hash.split("/")[1];
export const language = window.location.hash.split("/")[2];
console.log({ cfgObjectId });
const isInIframe = window.self !== window.top;
export function MinimalApp(): JSX.Element {
  return (
    <MinimalAppContext>
      <GatewayProvider>
        <Grid container spacing={10}>
          <Grid item width="100%">
            <Box padding={isInIframe ? 0 : 10}>
              <CommonErrorBoundary>
                {cfgObjectId ? (
                  <>
                    <MinimalRenderer
                      baseUrl={import.meta.env.VITE_CONFIGURATOR_BASE_URL}
                      cfgObjectId={cfgObjectId}
                      language={
                        language ? convertToLongLanguageCode(language) : "de-DE"
                      }
                    />
                  </>
                ) : (
                  <CFGObjectSelect />
                )}
              </CommonErrorBoundary>
              <OAuthManager
                basePath={import.meta.env.VITE_CONFIGURATOR_BASE_URL}
                isLocalDevelopment={import.meta.env.DEV}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box position="sticky" top={0}>
              <GatewayDest name="navigation" />
            </Box>
          </Grid>
        </Grid>
      </GatewayProvider>
    </MinimalAppContext>
  );
}
