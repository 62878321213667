import { useMemo } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { ConfigurationObjectResult } from "@plansoft-configuration-renderer/configuration-api";
import { useCsAttribute } from "@plansoft-configuration-renderer/framework";

import { useCustomSubstructureContext } from "../CustomSubstructureEmbedded/CustomSubstructureContext";
import {
  CustomSubstructureAddOrNextButton,
  CustomSubstructureDeleteButton,
  CustomSubstructurePreviousButton,
} from "../CustomSubstructureInstanceButtons/CustomSubstructureInstanceButtons";

export function CustomButtonBar(): JSX.Element {
  const attribute = useCsAttribute();

  const substructures: ConfigurationObjectResult[] = useMemo(() => {
    return attribute.configurations ?? [];
  }, [attribute.configurations]);

  const { guids, activeIndex } = useCustomSubstructureContext();

  const substructure = substructures.find(
    (substructure) => substructure.guid === guids[activeIndex],
  );

  return activeIndex != undefined && substructure != undefined ? (
    <Box display={"flex"}>
      <Box
        display={"flex"}
        flexBasis={"22rem"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} justifyContent={"start"}>
          <CustomSubstructurePreviousButton />
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <CustomSubstructureDeleteButton substructure={substructure} />
        </Box>
        <Box display={"flex"} justifyContent={"end"}>
          <CustomSubstructureAddOrNextButton />
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        flexGrow={1}
        marginInline={"5rem"}
      >
        <Button fullWidth> Alle Losteile abgeschlossen </Button>
      </Box>
    </Box>
  ) : (
    <Box></Box>
  );
}
