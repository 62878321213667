import { Order, Product } from "../renderer/ProductTable/types";

const help = [
  "Z_GRIP_HUB",
  "Z_GRIP_FORCE",
  "A_GRIP_STRENGTH",
  "Z_GRIP_STRENGTH_40",
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  const helpA = a[orderBy];
  const helpB = b[orderBy];
  if (
    help.includes(orderBy as string) &&
    typeof helpA === "string" &&
    typeof helpB === "string"
  ) {
    if (helpA !== "" && helpB === "") {
      return -1;
    }
    if (helpB !== "" && helpA === "") {
      return 1;
    }

    if (parseInt(helpB) < parseInt(helpA)) {
      return -1;
    }
    if (parseInt(helpB) > parseInt(helpA)) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

export function getComparator<Key extends keyof Product>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in keyof Product]: number | string },
  b: { [key in keyof Product]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function generateTimestampKey(): string {
  return Date.now().toString();
}

export function tryParseOutputData<T>(data: Partial<T>): T {
  if ("output" in data && typeof data["output"] === "string") {
    try {
      const result = JSON.parse(data["output"]);
      if (result && typeof result === "object") {
        data["output"] = result;
      }
    } catch (error) {
      console.error("Error parsing output data:", error);
    }
  }
  return data as T;
}
