import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { useCustomTranslation } from "@plansoft-configuration-renderer/localization";

import { ZimmerArrowIcon } from "@/icons/ZimmerIcons";
import { getComparator } from "@/tools/helper";
import { internationalizeLink } from "@/tools/internationalizeLink";

import { ButtonBar } from "./ButtonBar";
import { ProductTableBody } from "./ProductTableBody";
import { ProductTableHead } from "./ProductTableHead";
import { Header, Order, Product, Products } from "./types";

type Props = {
  products: Products;
  header: Header[];
  roboterManufacturer?: string;
  roboterKey?: string;
};

export function ProductTable({
  products,
  header: headers,
  roboterManufacturer,
  roboterKey,
}: Props): JSX.Element {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof Product>("ROWID");
  const [selected, setSelected] = useState<number[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [visibleProducts, setVisibleProducts] = useState<Products>([]);
  const { t } = useCustomTranslation();

  useEffect(() => {
    setVisibleProducts(
      products
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    );
  }, [page, rowsPerPage, products, order, orderBy]);

  useEffect(() => {
    setPage(0);
  }, [products, orderBy]);

  const handleClick = (id: number): void => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const compare = (): void => {
    const pimNumbers = selected
      .map((pim) => products.find(({ ROWID }) => ROWID === pim)?.Z_PIM_ID)
      .join(",");

    const genericLink = `${
      import.meta.env.VITE_APP_COMPARISON_LINK
    }${pimNumbers}`;
    window.open(internationalizeLink(genericLink), "_blank");
  };

  const handleRequestSort = (property: keyof Product): void => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    _: React.ChangeEvent<unknown> | null,
    newPage: number,
  ): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Box
      position={"relative"}
      display={"flex"}
      flexDirection={"column"}
      borderTop={"1px solid rgba(0,0,0,.1)"}
      paddingTop={"15px"}
      gap={"15px"}
    >
      <ButtonBar
        selected={selected}
        setSelected={setSelected}
        compare={compare}
      />
      <Box fontSize="14px">
        {t("translations.DICT_REOA_COMBINATIONS.name", {
          combinations: products.length,
        })}
      </Box>
      <TableContainer>
        <Table aria-labelledby="tableTitle">
          <ProductTableHead
            headers={headers}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <ProductTableBody
            products={visibleProducts}
            roboterManufacturer={roboterManufacturer}
            roboterKey={roboterKey}
            selected={selected}
            handleClick={handleClick}
            headers={headers}
          />
        </Table>
      </TableContainer>
      <TablePagination
        color="primary"
        slots={{
          actions: {
            firstButtonIcon: () => <div> test </div>,
            lastButton: () => <div>last</div>,
            previousButtonIcon: () => <ZimmerArrowIcon direction="left" />,
            nextButtonIcon: () => <ZimmerArrowIcon direction="right" />,
          },
        }}
        component="div"
        count={products.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={({ count, page }) =>
          `${page + 1}/${Math.floor(count / 10) + 1}`
        }
      />
    </Box>
  );
}
