import Grid from "@mui/material/Grid";
import {
  InfoValuesControlProps,
  useInfoValuesLayoutProps,
} from "@plansoft-configuration-renderer/components";
import {
  CsValueController,
  useCsAttribute,
} from "@plansoft-configuration-renderer/framework";

/**
 * Displays selected values as help text or markdown
 * @param props
 * @returns
 */

interface CustomLayoutProps {
  custom: {
    wwr_img?: boolean;
  };
}

export function CustomInfoValues(
  props: InfoValuesControlProps & Partial<CustomLayoutProps>,
): JSX.Element {
  const attribute = useCsAttribute();

  const { gridSpacing, gridDirection, gridBreakpoints } =
    useInfoValuesLayoutProps();

  const wwr_img = props.custom?.wwr_img;

  return (
    <Grid container direction={gridDirection} spacing={gridSpacing}>
      {attribute.values
        ?.filter((value) => value.isSelected)
        .map((value) => (
          <CsValueController key={value.id} value={value}>
            <Grid
              item
              {...gridBreakpoints}
              className={wwr_img ? "wwr_img" : ""}
            >
              {props.children}
            </Grid>
          </CsValueController>
        ))}
    </Grid>
  );
}
