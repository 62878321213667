import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { HelpPopover } from "@plansoft-configuration-renderer/components";

import { ZimmerArrowIcon } from "@/icons/ZimmerIcons";
import { ReactMarkdownWithHTML } from "@/tools/ReactMarkdownWithHTML";

import { Header, Order, Product } from "./types";

type Props = {
  headers: Header[];
  order: Order;
  orderBy: keyof Product;
  onRequestSort: (property: keyof Product) => void;
};

export function ProductTableHead({
  headers,
  order,
  orderBy,
  onRequestSort,
}: Props): JSX.Element {
  return (
    <TableHead>
      <TableRow>
        <TableCell>{/*checkbox*/}</TableCell>
        {headers.map(({ key, label, tooltip }) => (
          <TableCell
            key={key}
            align="left"
            sortDirection={orderBy === key ? order : false}
            onClick={(_) => onRequestSort(key)}
          >
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <TableSortLabel
                active={orderBy === key}
                direction={orderBy === key ? order : "asc"}
                IconComponent={(props) => {
                  return (
                    <ZimmerArrowIcon
                      {...props}
                      direction="down"
                      style={{
                        fontSize: "10px",
                      }}
                    />
                  );
                }}
              >
                <ReactMarkdownWithHTML>{label}</ReactMarkdownWithHTML>
              </TableSortLabel>
              {tooltip ? (
                <HelpPopover>
                  <ReactMarkdownWithHTML>{tooltip}</ReactMarkdownWithHTML>
                </HelpPopover>
              ) : null}
            </Box>
          </TableCell>
        ))}
        <TableCell>{/*action items*/}</TableCell>
      </TableRow>
    </TableHead>
  );
}
