import { useSessionId } from "@plansoft-configuration-renderer/framework";
import { useQuery } from "@tanstack/react-query";

import { LoadingSpinner } from "@/tools/LoadingSpinner";
import { fetchUserDefinedGetter } from "@/tools/api";
import { tryParseOutputData } from "@/tools/helper";

import { OptionalComponents } from "./OptionalComponents";
import { OptionalComponentsResponse } from "./types";

export function OptionalComponentsData(): JSX.Element {
  const sessionId = useSessionId();

  const {
    data: optionalComponentsData,
    isFetching,
    isSuccess,
  } = useQuery<OptionalComponentsResponse>({
    queryKey: ["optionalComponent"],
    queryFn: () =>
      fetchUserDefinedGetter(
        sessionId,
        "getOptionalComponents",
        tryParseOutputData,
      ),
  });

  if (isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {isSuccess && (
        <OptionalComponents
          optionalComponentsData={optionalComponentsData.output}
        />
      )}
    </>
  );
}
