import i18next from "i18next";

export function internationalizeLink(link: string): string {
  if (typeof link !== "string") {
    console.warn("internationalizeLink expects a string, instead got: ", link);
    return "";
  }

  let correctedLink: string = link.replace(
    "/%LANG%",
    `/${i18next.languages[0].toLowerCase()}`,
  );
  if (i18next.languages[0] === "de") {
    correctedLink = correctedLink.replace("/product/", "/produkt/");
  } else {
    correctedLink = correctedLink.replace("/produkt/", "/product/");
  }
  return correctedLink;
}
