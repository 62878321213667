import rehypeRaw from "rehype-raw";

import ReactMarkdown from "react-markdown";

interface ReactMarkdownWithHTMLProps {
  children: string;
}

export function ReactMarkdownWithHTML({
  children,
}: ReactMarkdownWithHTMLProps): JSX.Element {
  return <ReactMarkdown rehypePlugins={[rehypeRaw]}>{children}</ReactMarkdown>;
}
