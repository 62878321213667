import { accessoryIsTranslatable } from "./accessoryIsTranslatable";
import { andPlaceholder, orPlaceholder } from "./parseArticleNumber";

export function getArticleNumbersFromAccessories(
  accessories: string | undefined,
): string[] {
  if (accessories === undefined) {
    return [];
  }
  if (accessoryIsTranslatable(accessories)) {
    return [];
  }
  if (
    accessories === "DICT_REOA_ADAPTION_INTEGRIERT" ||
    accessories === "DICT_REOA_ACCESSORIES_INTEGRIERT"
  ) {
    return ["integriert"];
  }
  const regexp = new RegExp(`${orPlaceholder}|${andPlaceholder}`);
  return accessories.split(regexp).filter((str) => str !== "");
}
