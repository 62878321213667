import Checkbox from "@mui/material/Checkbox";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useCustomTranslation } from "@plansoft-configuration-renderer/localization";

import { generateTimestampKey } from "@/tools/helper";
import { internationalizeLink } from "@/tools/internationalizeLink";
import { parseArticleNumber } from "@/tools/parseArticleNumber";

import { IconFunctionCell } from "./IconFunctionCell";
import { Header, Products } from "./types";

type Props = {
  products: Products;
  selected: number[];
  handleClick: (id: number) => void;
  headers: Header[];
  roboterManufacturer?: string;
  roboterKey?: string;
};

export function ProductTableBody({
  products,
  selected,
  handleClick,
  headers,
  roboterManufacturer,
  roboterKey,
}: Props): JSX.Element {
  const { t } = useCustomTranslation();

  const isSelected = (id: number | undefined): boolean =>
    id ? selected.includes(id) : false;

  return (
    <TableBody>
      {products.map((product) => {
        const isItemSelected = isSelected(product.ROWID);
        return (
          <TableRow
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={product.ROWID || generateTimestampKey()}
            selected={isItemSelected}
          >
            <TableCell
              padding="checkbox"
              onClick={() =>
                product.ROWID ? handleClick(product.ROWID) : null
              }
            >
              <Checkbox checked={isItemSelected} color="primary" />
            </TableCell>
            <>
              {headers.map((header) => {
                if (product[header.key] === undefined) {
                  console.warn(`Error: ${header.key} does not exist`);
                }
                const key = product.ROWID
                  ? `${product.ROWID}_${header.key}`
                  : `${generateTimestampKey()}_${header.key}`;

                switch (header.key) {
                  case "Z_IMAGES":
                  case "Z_ADAPT_IMAGES":
                    return (
                      <TableCell align="left" width="120px" key={key}>
                        {product[header.key] != "" ? (
                          <img
                            src={
                              import.meta.env.VITE_APP_ASSETS_URL +
                              product[header.key]
                            }
                          />
                        ) : null}
                      </TableCell>
                    );
                  case "A_ARTICLE_NUMBER":
                    return (
                      <TableCell align="left" key={key}>
                        <a
                          href={internationalizeLink(
                            product.Z_DEEPLINKS || " ",
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>{product.A_ARTICLE_NUMBER}</span>
                        </a>
                      </TableCell>
                    );
                  case "Z_ADAPTION":
                    return (
                      <TableCell align="left" key={key}>
                        {product.Z_ADAPTION
                          ? parseArticleNumber(product.Z_ADAPTION, t)
                          : null}
                      </TableCell>
                    );
                  case "Z_ACCESSORIES":
                    return (
                      <TableCell align="left" key={key}>
                        {product.Z_ACCESSORIES
                          ? parseArticleNumber(product.Z_ACCESSORIES, t)
                          : null}
                      </TableCell>
                    );
                  default:
                    return (
                      <TableCell align="left" key={key}>
                        {product[header.key]}
                      </TableCell>
                    );
                }
              })}
            </>
            <TableCell align="left">
              <IconFunctionCell
                product={product}
                t={t}
                roboterManufacturer={roboterManufacturer}
                roboterKey={roboterKey}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
