import { HTMLAttributes } from "react";

type Direction = "down" | "left" | "up" | "right";

export function ZimmerMessageIcon({
  style,
  ...rest
}: HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return (
    <span
      {...rest}
      style={{
        fontFamily: "ZimmerIcons",
        ...style,
      }}
    >
      {"\uF2B7"}
    </span>
  );
}

export function ZimmerCartIcon({
  style,
  ...rest
}: HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return (
    <span
      {...rest}
      style={{
        fontFamily: "ZimmerIcons",
        ...style,
      }}
    >
      {"\uE904"}
    </span>
  );
}

export function ZimmerCheckIcon({
  style,
  ...rest
}: HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return (
    <span
      {...rest}
      style={{
        fontFamily: "ZimmerIcons",
        ...style,
      }}
    >
      {"\uF00C"}
    </span>
  );
}

export function ZimmerCrossIcon({
  style,
  ...rest
}: HTMLAttributes<HTMLSpanElement>): JSX.Element {
  return (
    <span
      {...rest}
      style={{
        fontFamily: "ZimmerIcons",
        ...style,
      }}
    >
      {"\uF00D"}
    </span>
  );
}

export function ZimmerArrowIcon({
  direction = "right",
  style,
  ...rest
}: HTMLAttributes<HTMLSpanElement> & { direction?: Direction }): JSX.Element {
  return (
    <span
      {...rest}
      style={{
        fontFamily: "ZimmerIcons",
        rotate: getDegree(direction),
        ...style,
      }}
    >
      {"\uE900"}
    </span>
  );
}

function getDegree(direction: Direction): string {
  switch (direction) {
    case "down":
      return "90deg";
    case "left":
      return "180deg";
    case "up":
      return "270deg";
    case "right":
    default:
      return "0deg";
  }
}
