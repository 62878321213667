import { useSessionId } from "@plansoft-configuration-renderer/framework";
import { useQuery } from "@tanstack/react-query";

import { LoadingSpinner } from "@/tools/LoadingSpinner";
import { fetchUserDefinedGetter } from "@/tools/api";
import { tryParseOutputData } from "@/tools/helper";

import { ProductTable } from "./ProductTable";
import { TableDataResponse } from "./types";

export function ProductTableData(): JSX.Element {
  const sessionId = useSessionId();

  const {
    data: productTableData,
    isFetching,
    isSuccess,
  } = useQuery<TableDataResponse>({
    queryKey: ["productTable"],
    queryFn: () =>
      fetchUserDefinedGetter(sessionId, "getTableData", tryParseOutputData),
  });

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (
    isSuccess &&
    (!productTableData?.output?.ROBOT_KEY ||
      !productTableData.output.SOM_ROBHER)
  ) {
    console.error("Error: getTableData provided wrong data: ", {
      data: productTableData?.output.data,
    });
  }

  return (
    <>
      {isSuccess && (
        <ProductTable
          products={productTableData.output.data}
          roboterManufacturer={productTableData.output.SOM_ROBHER}
          roboterKey={productTableData.output.ROBOT_KEY}
          header={productTableData.output.header}
        />
      )}
    </>
  );
}
