import { Fragment, Suspense } from "react";

import { ReactMarkdownWithHTML } from "@/tools/ReactMarkdownWithHTML";

/**
 * Markdown renderer
 *
 * @export
 * @param {string} src
 * @returns {{ __html: string }}
 */
export function CustomMarkdown({ src }: { src: string }): JSX.Element {
  if (!src) {
    return <Fragment />;
  }

  // we could use transformImageUri, but it is a security risk, see https://github.com/remarkjs/react-markdown#security
  return (
    <Suspense>
      <ReactMarkdownWithHTML>{src}</ReactMarkdownWithHTML>
    </Suspense>
  );
}
