/**
 * Checks if the accessory (or adaptation) string is a translation id.
 * Accessories can be either article numbers or may require translation.
 * If they require translation they start with the identifier `_x_`.
 * This function checks if this identifier is present and translation is required.
 * @param accessory
 */
export function accessoryIsTranslatable(accessory: string): boolean {
  return accessory.startsWith("_x_");
}
