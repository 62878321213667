import { Fragment } from "react";

import Box from "@mui/material/Box";
import { SubstructureContent } from "@plansoft-configuration-renderer/components";
import { ConfigurationObjectResult } from "@plansoft-configuration-renderer/configuration-api";

import { WwrLoadingSkeleton } from "@/tools/LoadingSkeleton";

import { useCustomSubstructureContext } from "../CustomSubstructureEmbedded/CustomSubstructureContext";

/**
 * Props for {@linkcode CustomSubstructureEmbeddedInstance}
 */
export interface CustomSubstructureEmbeddedInstanceProps {
  substructure: ConfigurationObjectResult;
}

/**
 * Single instance of a sub configuration
 * @param props
 * @returns
 */
export function CustomSubstructureEmbeddedInstance(
  props: CustomSubstructureEmbeddedInstanceProps,
): JSX.Element {
  const { substructure } = props;

  const { guids, activeIndex } = useCustomSubstructureContext();

  const content = (
    <SubstructureContent
      substructure={substructure}
      FallbackUi={WwrLoadingSkeleton}
      LoadingIndicator={WwrLoadingSkeleton}
    />
  );

  return (
    <Fragment>
      {activeIndex != undefined && guids[activeIndex] === substructure.guid && (
        <Box>
          {/* TODO{sh}: delete guid after testing */}
          {substructure.guid}
          {content}
        </Box>
      )}
    </Fragment>
  );
}

CustomSubstructureEmbeddedInstance.displayName =
  "CustomSubstructureEmbeddedInstance";
