import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

/**
 * Loading Skeleton for WER
 * px values required for skeleton to look excactly the same
 */
export function WerLoadingSkeleton(): JSX.Element {
  return (
    // Skeleton for one WER element
    <Skeleton
      variant="rectangular"
      width={"100%"}
      height={"36px"}
      sx={{ marginBlock: "10px" }}
    />
  );
}

/**
 * Loading Skeleton for WWR
 * px values required for skeleton to look excactly the same
 */
export function WwrLoadingSkeleton(): JSX.Element {
  return (
    <>
      <Box
        display={"flex"}
        gap={"1rem"}
        marginBottom={"1rem"}
        marginTop={"0.5rem"}
      >
        {/* IMG Skeleton */}
        <Skeleton variant="rectangular" height={"351px"} width={"340px"} />
        {/* WER Skeleton */}
        <Skeleton variant="rectangular" height={"351px"} sx={{ flex: 1 }} />
      </Box>
      {/* Button Bar Skeleton */}
      <Box display={"flex"} gap={"1rem"}>
        <Skeleton variant="rectangular" height={"40px"} width={"340px"} />
        <Skeleton variant="rectangular" height={"40px"} sx={{ flex: 1 }} />
      </Box>
      {/* WER position Skeleton */}
      <Skeleton
        variant="rectangular"
        height={"23px"}
        width={"273px"}
        sx={{ marginTop: "8px" }}
      />
      <Skeleton
        variant="rectangular"
        height={"36px"}
        sx={{ marginBlock: "10px" }}
      />
      {/* Technical Table Skeleton */}
      <Skeleton
        variant="rectangular"
        height={"49.5px"}
        sx={{ marginBlock: "1rem" }}
      />
      {/* Technical Drawing Skeleton */}
      <Skeleton
        variant="rectangular"
        height={"49.5px"}
        sx={{ marginBlock: "1rem" }}
      />
    </>
  );
}
