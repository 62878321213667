import i18n from "i18next";

import { initReactI18next } from "react-i18next";

import { language } from "../MinimalApp";

import { de_DE } from "./de_DE";

// noinspection JSIgnoredPromiseFromCall

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    lng: language ? language : "de",
    fallbackLng: "cimode",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      de: de_DE,
    },
  });
