import { TFunction } from "i18next";

import IconButton from "@mui/material/IconButton";

import { ZimmerCartIcon, ZimmerMessageIcon } from "@/icons/ZimmerIcons";
import { assembleContactMessage } from "@/tools/assembleContactMessage";
import { getArticleNumbersFromAccessories } from "@/tools/getArticleNumbersFromAccessories";

import { Product } from "./types";

type Props = {
  product: Product;
  t: TFunction;
  roboterManufacturer?: string;
  roboterKey?: string;
};

export function IconFunctionCell({
  t,
  product,
  roboterManufacturer,
  roboterKey,
}: Props): JSX.Element {
  return (
    <span>
      <IconButton
        onClick={() => {
          const message = {
            action: "addArticlesToRequestCart",
            articleNumbers: [
              product.A_ARTICLE_NUMBER,
              ...getArticleNumbersFromAccessories(product.Z_ADAPTION),
              ...getArticleNumbersFromAccessories(product.Z_ACCESSORIES),
            ],
            isMatch: product.A_MATCH,
          };
          window.parent.postMessage(message, "*");
          console.log(message);
        }}
      >
        <ZimmerCartIcon />
      </IconButton>

      <IconButton
        onClick={() => {
          {
            const contactMessage = assembleContactMessage(t, {
              articleNumber: product.A_ARTICLE_NUMBER,
              adaptation: product.Z_ADAPTION,
              accessories: product.Z_ACCESSORIES,
              roboterManufacturer,
              roboterKey,
            });
            const message = {
              action: "setFormValue",
              formIdentifier: "contact",
              fieldIdentifier: "message",
              value: contactMessage,
              override: true,
              scrollToForm: true,
              scrollToField: false,
            };
            window.parent.postMessage(message, "*");
            console.log(message);
          }
        }}
      >
        <ZimmerMessageIcon />
      </IconButton>
    </span>
  );
}
