import Button from "@mui/material/Button";

import { OptionalComponentParagraphs } from "./OptionalComponentParagraphs";
import "./OptionalComponents.css";
import type { OptionalComponent } from "./types";

export function OptionalComponent({
  color,
  key,
  title,
  paragraphs,
  button,
  url,
}: OptionalComponent): JSX.Element {
  return (
    <div className={`optionalComponent ${color}`} key={key}>
      <>
        <div className="paragraphsContainer">
          <div className="headerContainer">
            <div className="rightArrow">▶</div>
            <h2>{title}</h2>
          </div>
          {paragraphs && (
            <OptionalComponentParagraphs
              paragraphs={paragraphs}
              componentKey={key}
            />
          )}
          {button?.label && (
            <a href={button.url} className="button">
              <Button>{button.label}</Button>
            </a>
          )}
        </div>
        <div className="ImgContainer">
          <img src={url} alt={url} className="img" />
        </div>
      </>
    </div>
  );
}
