import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { ZimmerCrossIcon } from "../../icons/ZimmerIcons";

export function CustomDialogTitle({
  title,
  subtitle,
  onClose,
}: {
  title?: string;
  subtitle?: string;
  onClose: () => void;
}): JSX.Element {
  return (
    <DialogTitle onClick={(e) => e.stopPropagation()}>
      {title}
      {subtitle ? <Typography variant="body1">{subtitle}</Typography> : null}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <ZimmerCrossIcon />
      </IconButton>
    </DialogTitle>
  );
}
