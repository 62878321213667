import {
  AttributeComponents,
  AttributeStrategy,
  CommonComponents,
  ConfigurationComponentType,
  ConfigurationComponents,
  ControlComponents,
  ControlComponentsMapping,
  LayoutComponentProvider,
  LayoutControlType,
} from "@plansoft-configuration-renderer/components";

import { NavigationOutsideLayout } from "./NavigationOutsideLayout";
import { CustomInfoValues } from "./controls/CustomInfoValues/CustomInfoValues";
import { CustomMarkdown } from "./controls/CustomMarkdown/CustomMarkdown";
import { CustomSubstructureContextProvider } from "./controls/CustomSubstructureEmbedded/CustomSubstructureContextProvider";
import { CustomThumbnail } from "./controls/CustomThumbnail/CustomThumbnail";
import { IndividualComponent } from "./controls/Individual";
import { IndividualComponentMapper } from "./controls/IndividualComponentMapper/IndividualComponentMapper";
import { IndividualContainerMapper } from "./controls/IndividualContainerMapper/IndividualContainerMapper";
import { SubstructureMapper } from "./controls/SubstructureMapper/SubstructureMapper";

// override control components for attributes
const controlComponents: ControlComponentsMapping = {
  ...ControlComponents, // the default components
  [LayoutControlType.Individual]: IndividualComponent, // override individual control
  [LayoutControlType.Thumbnail]: CustomThumbnail,
  [LayoutControlType.Substructure]: SubstructureMapper,
  [LayoutControlType.InfoValues]: CustomInfoValues,
};

// override configuration level components
const configurationComponents = {
  ...ConfigurationComponents,
  [ConfigurationComponentType.ConfigurationNavigation]: NavigationOutsideLayout,
  [ConfigurationComponentType.IndividualContainer]: IndividualContainerMapper,
  [ConfigurationComponentType.Individual]: IndividualComponentMapper,
};

// override attribute components
const attributeComponents = {
  ...AttributeComponents,
};

// override common components
const commonComponents = {
  ...CommonComponents,
  Markdown: CustomMarkdown,
};

export function CustomRenderer(): JSX.Element {
  // see properties of LayoutComponentProvider for more information
  return (
    <CustomSubstructureContextProvider>
      <LayoutComponentProvider
        configurationComponents={configurationComponents}
        attributeComponents={attributeComponents}
        controlComponents={controlComponents}
        commonComponents={commonComponents}
      >
        {/* Attribute strategy renders the components using the layout information */}
        <AttributeStrategy />
      </LayoutComponentProvider>
    </CustomSubstructureContextProvider>
  );
}
