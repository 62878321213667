import { ReactNode } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { customPalette } from "@/theme/theme";

import type { BasicTable } from "./types";

export function BasicTable<
  Row extends { id: number; recommended?: boolean } & {
    [K in keyof Row]: ReactNode;
  },
>({ headers, rows }: BasicTable<Row>): JSX.Element {
  return (
    <TableContainer>
      <Table aria-label="basicTable">
        <TableBody>
          {headers.map((header, index) => (
            <TableRow key={header.accessor}>
              <TableCell> {header.label} </TableCell>
              {rows.map((row) => (
                <TableCell
                  key={row.id}
                  style={{
                    paddingInline: "1rem",
                    backgroundColor: row.recommended
                      ? "rgb(0, 134, 205, 0.3)"
                      : "transparent",
                    borderColor: row.recommended
                      ? "rgb(0, 134, 205, 0.1)"
                      : "null",
                    borderInline: row.recommended
                      ? `1px solid ${customPalette.primary.main}`
                      : "null",
                    borderTop:
                      row.recommended && index === 0
                        ? `1px solid ${customPalette.primary.main}`
                        : "null",
                    borderBottom:
                      row.recommended && index === headers.length - 1
                        ? `1px solid ${customPalette.primary.main}`
                        : "null",
                  }}
                >
                  {row[header.accessor]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
