import { ReactNode } from "react";

import { Card, FormControl, Grid } from "@mui/material";
import {
  AttributeControlProps,
  ThumbnailActionArea,
  ThumbnailValueComponentsProvider,
  useThumbnailControlLayoutProps,
  useVisibleAttributeValues,
} from "@plansoft-configuration-renderer/components";
import {
  CsValueController,
  useLocalOptionSelected,
} from "@plansoft-configuration-renderer/framework";

export interface ThumbnailItemProps {
  children: ReactNode;
}

export function CustomThumbnail(props: AttributeControlProps): JSX.Element {
  const values = useVisibleAttributeValues();

  const { gridDirection, gridBreakpoints } = useThumbnailControlLayoutProps();

  return (
    <Grid container direction={gridDirection} spacing="0.625rem">
      {values.map((value) => {
        return (
          <Grid
            item
            {...gridBreakpoints}
            key={value.id}
            className={"thumbnail-card"}
          >
            <FormControl>
              <CsValueController value={value}>
                <CustomThumbnailItem>{props.children}</CustomThumbnailItem>
              </CsValueController>
            </FormControl>
          </Grid>
        );
      })}
    </Grid>
  );
}

export function CustomThumbnailItem(props: ThumbnailItemProps): JSX.Element {
  // there is a isSelected value but it does not work with local values
  const isSelected = useLocalOptionSelected();

  // Note: do not change "elevation" property of Card/Paper since it interferes with "raised"
  return (
    <Card className={isSelected ? "active" : ""}>
      <ThumbnailValueComponentsProvider>
        <ThumbnailActionArea>{props.children}</ThumbnailActionArea>
      </ThumbnailValueComponentsProvider>
    </Card>
  );
}
