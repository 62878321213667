import Alert from "@mui/material/Alert";
import { CustomLayoutProps } from "@plansoft-configuration-renderer/layout";
import { useUiTranslation } from "@plansoft-configuration-renderer/localization";

import { CustomButtonBar } from "../CustomButtonBar/CustomButtonBar";

/**
 * Props for {@link IndividualComponentMapper}
 */
export type IndividualComponentMapperProps = {
  variant: "custom_button_component" | string;
} & Required<CustomLayoutProps>;

/**
 * Individual component on configuration level
 * - renders an alert by default
 * - meant to be overridden in custom renderers
 * @param props
 * @returns
 */
export function IndividualComponentMapper({
  variant,
}: IndividualComponentMapperProps): JSX.Element {
  const { t } = useUiTranslation();

  if (variant === "custom_button_component") {
    return <CustomButtonBar />;
  }

  return (
    <Alert severity="warning">
      {t(
        "Individual components not supported",
        "Individual components are not supported by the default renderer",
      )}
    </Alert>
  );
}
