import { OptionalComponent } from "./OptionalComponent";
import "./OptionalComponents.css";
import type { OptionalComponents } from "./types";

type Props = {
  optionalComponentsData: OptionalComponents;
};

export function OptionalComponents({
  optionalComponentsData,
}: Props): JSX.Element {
  return (
    <div className="optionalComponentsContainer">
      {optionalComponentsData.map((optionalComponent) => {
        return (
          <OptionalComponent
            {...optionalComponent}
            key={optionalComponent.key}
          />
        );
      })}
    </div>
  );
}
