export async function fetchUserDefinedGetter<T>(
  sessionId: string,
  endpoint: "getTableData" | "getOptionalComponents",
  parser?: (data: Partial<T>) => T,
): Promise<T> {
  try {
    const res = await fetch(
      `/preview/api/3pq-cs/v2/configuration/configurations/${sessionId}/userDefinedGetter/${endpoint}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      },
    );

    if (!res.ok) {
      throw new Error(`${res.status} - ${res.statusText}`);
    }

    const data = await res.json();
    if (parser) {
      return parser(data);
    }
    return data;
  } catch (error) {
    console.error(error);
  }
  throw new Error(
    "Logic error: fetchUserDefinedGetter should never reach this point.",
  );
}
