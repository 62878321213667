import { TFunction } from "i18next";

import React from "react";

import { internationalizeLink } from "./internationalizeLink";

const splitter = "@_@";
export const orPlaceholder = "_o_";
export const andPlaceholder = "_u_";

const orRegEx = new RegExp(orPlaceholder, "g");
const andRegEx = new RegExp(andPlaceholder, "g");

const orReplacement = splitter + orPlaceholder + splitter;
const andReplacement = splitter + andPlaceholder + splitter;

export function parseArticleNumber(
  artNumber: string,
  t: TFunction,
): JSX.Element[] {
  const addedSplitter = artNumber
    .replace(orRegEx, orReplacement)
    .replace(andRegEx, andReplacement);
  const stringArray = addedSplitter.split(splitter);
  const parsed = stringArray.map((str, index) => {
    if (str === orPlaceholder) {
      return (
        <React.Fragment key={index + str}>
          {t("translations.DICT_REOA_OR.name")} {/* oder{" "} */}
        </React.Fragment>
      );
    }
    if (str === andPlaceholder) {
      return (
        <React.Fragment key={index + str}>
          {t("translations.DICT_REOA_AND.name")} {/* und{" "} */}
        </React.Fragment>
      );
    }
    // It is no placeholder, so the string is a product number which has to become a link
    // check for integrated product
    const integriert = str.includes("INTEGRIERT");

    return !integriert ? (
      str !== "APR000000" ? (
        <a
          key={index + str}
          href={internationalizeLink(
            import.meta.env.VITE_APP_PRODUCT_LINK + str,
          )}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span style={{ whiteSpace: "nowrap" }}>{str}</span>
        </a>
      ) : (
        <>{str}</>
      )
    ) : (
      <span key={index + str} style={{ whiteSpace: "nowrap" }}>
        {t("translations." + str + ".name")}
      </span>
    );
  });

  return parsed;
}
