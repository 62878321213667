import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import {
  CommonErrorBoundary,
  IndividualControlLayoutProps,
} from "@plansoft-configuration-renderer/components";

import { ImagePreview } from "@/components/ImagePreview/ImagePreview";

import { OptionalComponentsData } from "../OptionalComponents/OptionalComponentsData";
import { ProductTableData } from "../ProductTable/ProductTableData";

import { TechnicalTable } from "./TechnicalTable/TechnicalTable";
import { TechnicalTableMock } from "./TechnicalTable/TechnicalTable.mock";

export function IndividualComponent(
  props: IndividualControlLayoutProps,
): JSX.Element {
  const { variant = "variantFallback", custom = {} } = props;

  switch (variant) {
    case "optional_components":
      return <OptionalComponentsData />;
    case "product_table":
      return (
        <CommonErrorBoundary>
          <ProductTableData />
        </CommonErrorBoundary>
      );
    case "wwr_lp_data_table":
      return (
        <TechnicalTable
          rows={TechnicalTableMock.rows}
          headers={TechnicalTableMock.headers}
        />
      );
    case "wwr_lp_data_drw":
      return <ImagePreview imageId={"test"} />;

    default:
      return (
        <Box my={2}>
          <Alert>
            <AlertTitle>{variant}</AlertTitle>
            <code>{JSON.stringify(custom, null, 2)}</code>
          </Alert>
        </Box>
      );
  }
}
