export const de_DE = {
  configuration: {
    translations: {
      DICT_REOA_CONTACT_REQUEST: {
        name: "Ich habe eine Anfrage zum Artikel {{articleNumber}}",
      },
      DICT_REOA_CONTACT_REQUEST_ADAPTATION: {
        name: "Best-Nr. benötigte Adaptierung: {{adaptationList}}",
      },
      DICT_REOA_CONTACT_REQUEST_ACCESSORIES: {
        name: "Best-Nr. benötigtes Zubehör: {{accessoriesList}}",
      },
      DICT_REOA_ADAPTION_INTEGRIERT: {
        name: "integriert",
      },
      DICT_REOA_ACCESSORIES_INTEGRIERT: {
        name: "integriert",
      },
    },
  },
};
