import { TFunction } from "i18next";

import { accessoryIsTranslatable } from "./accessoryIsTranslatable";

export function assembleContactMessage(
  t: TFunction,
  {
    articleNumber,
    adaptation,
    accessories,
    roboterManufacturer,
    roboterKey,
  }: {
    articleNumber: string | undefined;
    adaptation: string | undefined;
    accessories: string | undefined;
    roboterManufacturer: string | undefined;
    roboterKey: string | undefined;
  },
): string {
  let message = t("translations.DICT_REOA_CONTACT_REQUEST.name", {
    articleNumber,
    roboterManufacturer,
    roboterKey,
  });
  if (adaptation) {
    message +=
      "\n" +
      t("translations.DICT_REOA_CONTACT_REQUEST_ADAPTATION.name", {
        adaptationList: handleTranslationOrSplitting(adaptation, t),
      });
  }
  if (accessories) {
    message +=
      "\n" +
      t("translations.DICT_REOA_CONTACT_REQUEST_ACCESSORIES.name", {
        accessoriesList: handleTranslationOrSplitting(accessories, t),
      });
  }

  return message;
}

/**
 * Provides the translated or split string for the contact message.
 * @see accessoryIsTranslatable for more details
 * @param accessories
 * @param t
 */
function handleTranslationOrSplitting(
  accessories: string,
  t: TFunction,
): string {
  if (
    accessories === "DICT_REOA_ADAPTION_INTEGRIERT" ||
    accessories === "DICT_REOA_ACCESSORIES_INTEGRIERT"
  ) {
    return "integriert";
  }
  return accessoryIsTranslatable(accessories)
    ? t(accessories)
    : accessories.split(/_[ou]_/).join(", ");
}
