import Button from "@mui/material/Button";
import { useIcon } from "@plansoft-configuration-renderer/components";
import { ConfigurationObjectResult } from "@plansoft-configuration-renderer/configuration-api";
import {
  useCsAttribute,
  useSubstructureApi,
  useSubstructureMeta,
} from "@plansoft-configuration-renderer/framework";
import { useUiTranslation } from "@plansoft-configuration-renderer/localization";

import { ZimmerArrowIcon } from "@/icons/ZimmerIcons";

import { useCustomSubstructureContext } from "../CustomSubstructureEmbedded/CustomSubstructureContext";

export function CustomSubstructureAddButton(): JSX.Element {
  const attribute = useCsAttribute();
  const { addSubCfgObject } = useSubstructureApi();
  const { canAdd } = useSubstructureMeta();
  const SubstructureAddIcon = useIcon("SubstructureAddIcon");

  return (
    <>
      {canAdd && (
        <Button
          type="button"
          onClick={() => addSubCfgObject({ path: attribute.path })}
        >
          <SubstructureAddIcon />
        </Button>
      )}
    </>
  );
}

CustomSubstructureAddButton.displayName = "CustomSubstructureAddButton";

export function CustomSubstructureDeleteButton({
  substructure,
}: {
  substructure: ConfigurationObjectResult;
}): JSX.Element {
  const attribute = useCsAttribute();
  const { deleteSubCfgObject } = useSubstructureApi();
  const { canDelete } = useSubstructureMeta();
  const instancePath = `${attribute.path}/${substructure.index}`;

  const { t } = useUiTranslation();

  return (
    <>
      {canDelete && (
        <Button
          type="button"
          onClick={() => deleteSubCfgObject({ path: instancePath })}
        >
          {t("Delete substructure", "Delete")}
        </Button>
      )}
    </>
  );
}

CustomSubstructureDeleteButton.displayName = "CustomSubstructureDeleteButton";

export function CustomSubstructurePreviousButton(): JSX.Element {
  const { guids, activeIndex, setActiveIndex } = useCustomSubstructureContext();

  const canPrevious = guids.length > 1 && activeIndex > 0;

  return canPrevious ? (
    <Button type="button" onClick={() => setActiveIndex(activeIndex - 1)}>
      <ZimmerArrowIcon direction="left" />
    </Button>
  ) : (
    <Button disabled className="previous-disabled"></Button>
  );
}

CustomSubstructurePreviousButton.displayName =
  "CustomSubstructurePreviousButton";

export function CustomSubstructureNextButton(): JSX.Element {
  const { guids, activeIndex, setActiveIndex } = useCustomSubstructureContext();

  const canNext = guids.length > 1 && activeIndex !== guids.length - 1;

  return (
    <>
      {canNext && (
        <Button type="button" onClick={() => setActiveIndex(activeIndex + 1)}>
          <ZimmerArrowIcon direction="right" />
        </Button>
      )}
    </>
  );
}

CustomSubstructureNextButton.displayName = "CustomSubstructureNextButton";

export function CustomSubstructureAddOrNextButton(): JSX.Element {
  const { guids, activeIndex } = useCustomSubstructureContext();
  const canAdd = activeIndex === guids.length - 1;

  return (
    <>
      {canAdd ? (
        <CustomSubstructureAddButton />
      ) : (
        <CustomSubstructureNextButton />
      )}
    </>
  );
}

CustomSubstructureAddOrNextButton.displayName =
  "CustomSubstructureAddOrNextButton";
