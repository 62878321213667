import {
  Substructure,
  SubstructureLayoutProps,
} from "@plansoft-configuration-renderer/components";

import { CustomSubstructure } from "../CustomSubstructure/CustomSubstructure";

export function SubstructureMapper(
  props: Partial<SubstructureLayoutProps>,
): JSX.Element {
  //overrides only the substructure with CustomSubstructure and keeps the default behaviour for the rest
  if (props.custom?.renderAsWWR || props.custom?.renderAsWER) {
    return (
      <div
        className={
          props.custom?.renderAsWER ? "wer_component" : "wwr_component"
        }
      >
        <CustomSubstructure {...props} />
      </div>
    );
  }
  return <Substructure {...props} />;
}

SubstructureMapper.displayName = "SubstructureMapper";
