import { Fragment, ReactNode } from "react";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { CustomLayoutProps } from "@plansoft-configuration-renderer/layout";
import { useUiTranslation } from "@plansoft-configuration-renderer/localization";

/**
 * Props for {@link IndividualContainer}
 */
export type IndividualContainerMapperProps = {
  /**
   * Variant to distinguish between different individual components
   */
  variant: "wer_container" | "wwr_container" | string;
  /**
   * Child components, inserted by layout
   */
  children: ReactNode;
} & Required<CustomLayoutProps>;

/**
 * Individual container component on configuration level
 * - only renders child layout by default
 * - meant to be overridden in custom renderers
 * @param props
 * @returns
 */
export function IndividualContainerMapper(
  props: IndividualContainerMapperProps,
): JSX.Element {
  const { t } = useUiTranslation();

  if (props.variant === "wer_container" || props.variant === "wwr_container") {
    return <Box className={props.variant}>{props.children}</Box>;
  }

  return (
    <Fragment>
      <Alert severity="warning">
        {t(
          "Individual containers not supported",
          "Individual containers are not supported by the default renderer and display only their content",
        )}
      </Alert>
      {props.children}
    </Fragment>
  );
}
