import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useCustomTranslation } from "@plansoft-configuration-renderer/localization";

import { ZimmerMessageIcon } from "@/icons/ZimmerIcons";

type Props = {
  selected: number[];
  setSelected: React.Dispatch<React.SetStateAction<number[]>>;
  compare: () => void;
};

export function ButtonBar({
  selected,
  setSelected,
  compare,
}: Props): JSX.Element {
  const { t } = useCustomTranslation();

  const request = {
    action: "setFormValue",
    formIdentifier: "contact",
    fieldIdentifier: "message",
    value: t("translations.DICT_REOA_HELP_MESSAGE.name"),
    override: true,
    scrollToForm: true,
    scrollToField: false,
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", gap: "15px" }}>
        <Button disabled={selected.length === 0} onClick={() => compare()}>
          {t("translations.DICT_REOA_COMPARE_PRODUCTS.name")}
        </Button>
        <Button
          disabled={selected.length === 0}
          onClick={() => setSelected([])}
        >
          {t("translations.DICT_REOA_DELETE_SELECTION.name")}
        </Button>
      </Box>
      <Button
        endIcon={<ZimmerMessageIcon />}
        onClick={() => {
          console.log({ request, selected });
          window.parent.postMessage(request, "*");
        }}
      >
        <span>{t("translations.DICT_REOA_CONTACT_BUTTON.name")}</span>
      </Button>
    </Box>
  );
}
