import { TechnicalTable } from "./types";

export const TechnicalTableMock: TechnicalTable = {
  headers: [
    {
      accessor: "amount_couplings",
      label: "Anzahl Fluidkopplungen",
    },
    {
      accessor: "suitable_for",
      label: "Passend für",
    },
    {
      accessor: "connecting_thread",
      label: "Anschlussgewinde",
    },
    {
      accessor: "series",
      label: "Serie",
    },
    {
      accessor: "working_pressure",
      label: "Betriebsdruck",
    },
    {
      accessor: "transmission_type",
      label: "Übertragungsart",
    },
    {
      accessor: "protection",
      label: "Schutzart nach IEC 60529",
    },
    {
      accessor: "weight",
      label: "Gewicht",
    },
  ],
  rows: [
    {
      id: 1,
      amount_couplings: 4,
      suitable_for: "Festteil",
      connecting_thread: "M5",
      series: "WWR1000",
      working_pressure: "-0.6 ...10 [bar]",
      transmission_type: "pneumatisch",
      protection: "IP54",
      weight: "0.035 [kg]",
    },
    {
      id: 2,
      amount_couplings: 4,
      suitable_for: "Festteil",
      connecting_thread: "M5",
      series: "WWR1000",
      working_pressure: "-0.6 ...10 [bar]",
      transmission_type: "pneumatisch",
      protection: "IP54",
      weight: "0.035 [kg]",
    },
    {
      id: 3,
      recommended: true,
      amount_couplings: 4,
      suitable_for: "Festteil",
      connecting_thread: "M5",
      series: "WWR1000",
      working_pressure: "-0.6 ...10 [bar]",
      transmission_type: "pneumatisch",
      protection: "IP54",
      weight: "0.035 [kg]",
    },
    {
      id: 5,
      amount_couplings: 5,
      suitable_for: "Festteil",
      connecting_thread: "M5",
      series: "WWR1000",
      working_pressure: "-0.6 ...10 [bar]",
      transmission_type: "pneumatisch",
      protection: "IP54",
      weight: "0.035 [kg]",
    },
  ],
};
