import { Fragment } from "react";

import Box from "@mui/material/Box";
import { SubstructureContent } from "@plansoft-configuration-renderer/components";
import { ConfigurationObjectResult } from "@plansoft-configuration-renderer/configuration-api";
import { useCsAttribute } from "@plansoft-configuration-renderer/framework";

import { WerLoadingSkeleton } from "@/tools/LoadingSkeleton";

/**
 * Embedded sub configuration
 * @returns
 */
export function CustomSubstructureEmbeddedContent(): JSX.Element {
  const attribute = useCsAttribute();

  const substructures: ConfigurationObjectResult[] =
    attribute.configurations ?? [];

  return (
    <Fragment>
      {substructures.map((substructure, idx) => (
        <Box key={substructure.id + idx} display={"flex"} gap={"1rem"}>
          <Box alignContent={"center"} minWidth={"2rem"} textAlign={"end"}>
            {idx + 1}:
          </Box>
          <Box flexGrow={1}>
            <SubstructureContent
              key={idx}
              substructure={substructure}
              FallbackUi={WerLoadingSkeleton}
              LoadingIndicator={WerLoadingSkeleton}
            />
          </Box>
        </Box>
      ))}
    </Fragment>
  );
}

CustomSubstructureEmbeddedContent.displayName =
  "CustomSubstructureEmbeddedContent";
