import { useState } from "react";

import { CustomSubstructureContext } from "./CustomSubstructureContext";

export function CustomSubstructureContextProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [guids, setGuids] = useState<string[]>([]);

  return (
    <CustomSubstructureContext.Provider
      value={{
        activeIndex,
        setActiveIndex,
        guids,
        setGuids,
      }}
    >
      {children}
    </CustomSubstructureContext.Provider>
  );
}
