import Box from "@mui/material/Box";

import { BasicTable } from "../../../components/BasicTable/BasicTable";

import { TechnicalTableHeader, TechnicalTableRow } from "./types";

type TechnicalTableProps = {
  headers: TechnicalTableHeader[];
  rows: TechnicalTableRow[];
};

export function TechnicalTable({
  headers,
  rows,
}: TechnicalTableProps): JSX.Element {
  return (
    <Box>
      <BasicTable headers={headers} rows={rows} />
    </Box>
  );
}
