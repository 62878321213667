import { createContext, useContext } from "react";

interface CustomSubstructureContextType {
  activeIndex: number;
  setActiveIndex: (id: number) => void;
  guids: string[];
  setGuids: (guids: string[]) => void;
}

export const CustomSubstructureContext =
  createContext<CustomSubstructureContextType>({
    activeIndex: 0,
    setActiveIndex: () => {},
    guids: [],
    setGuids: () => {},
  });

export function useCustomSubstructureContext(): CustomSubstructureContextType {
  return useContext(CustomSubstructureContext);
}
