import SvgIcon from "@mui/material/SvgIcon";

import placeholderImg from "../../assets/placeholderImg.svg?react";

export function Image({
  imageId,
  alt,
  width,
  height,
}: {
  imageId?: string | null | undefined;
  alt?: string | null | undefined;
  width?: number;
  height?: number;
}): JSX.Element {
  return (
    <>
      {imageId ? (
        <img
          alt={alt || ""}
          src={`path/to/${imageId}`}
          width={width}
          height={height}
        />
      ) : (
        <SvgIcon
          inheritViewBox
          component={placeholderImg}
          sx={{ width, height }}
        />
      )}
    </>
  );
}
