import { Fragment, useCallback, useEffect, useMemo } from "react";

import { ConfigurationObjectResult } from "@plansoft-configuration-renderer/configuration-api";
import { useCsAttribute } from "@plansoft-configuration-renderer/framework";

import { CustomSubstructureEmbeddedInstance } from "../CustomSubstructureEmbeddedInstance/CustomSubstructureEmbeddedInstance";

import { useCustomSubstructureContext } from "./CustomSubstructureContext";

/**
 * Embedded sub configuration
 * @returns
 */
export function CustomSubstructureEmbedded(): JSX.Element {
  const attribute = useCsAttribute();

  const substructures: ConfigurationObjectResult[] = useMemo(() => {
    return attribute.configurations ?? [];
  }, [attribute.configurations]);

  const { setGuids, setActiveIndex } = useCustomSubstructureContext();

  const handleNewSubstructure = useCallback(
    (substructures: ConfigurationObjectResult[]) => {
      const newGuids = substructures.map((substructure) => substructure.guid);
      setGuids(newGuids);
      setActiveIndex(newGuids.length - 1);
    },
    [setActiveIndex, setGuids],
  );

  useEffect(() => {
    handleNewSubstructure(substructures);
  }, [substructures, handleNewSubstructure]);

  return (
    <Fragment>
      {substructures.map((substructure, idx) => (
        <CustomSubstructureEmbeddedInstance
          key={idx}
          substructure={substructure}
        />
      ))}
    </Fragment>
  );
}

CustomSubstructureEmbedded.displayName = "CustomSubstructureEmbedded";
